import type { User } from '@api/types'

export function init() {
  ;(function () {
    const w = window as any
    if (w.ko) return
    w.ko = []
    ;['identify', 'track', 'removeListeners', 'open', 'on', 'off', 'qualify', 'ready'].forEach(
      function (method) {
        w.ko[method] = function (...args: any[]) {
          const params = Array.prototype.slice.call(args)
          params.unshift(method)
          w.ko.push(params)
          return w.ko
        }
      },
    )
    const script = document.createElement('script')
    script.async = true
    script.setAttribute(
      'src',
      'https://cdn.getkoala.com/v1/pk_5684a950957d69372ba3d423d2fa66944f8a/sdk.js',
    )
    ;(document.body || document.head).appendChild(script)
  })()
}

export function identify(user: User) {
  const ko = (window as any).ko
  if (!ko) {
    return
  }
  try {
    ko.identify({
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    })
  } catch (err) {
    console.error('[koala] identify:', err)
  }
}

export function track(eventName: string, props?: Record<string, any>) {
  const ko = (window as any).ko
  if (!ko) {
    return
  }
  ko.track(eventName, props)
}

export function reset() {
  const ko = (window as any).ko
  if (!ko) {
    return
  }
  ko.reset()
}
